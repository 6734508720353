@import '../../../../styles/mixins.scss';

.pageWrapper {
  display: flex;
  gap: 70px;
  padding-top: 30px;
  width: 100%;

  @media (max-width: $tablet) {
    padding-top: 10px;
    flex-direction: column;
    gap: 30px;
  }
}

.leftPagePart {
  max-width: 492px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: $tablet) {
    max-width: 100%;
    flex-direction: row;
    gap: 10px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.rightPagePart {
  width: 100%;
}