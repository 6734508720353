@font-face {
  font-family: Gilroy;
  font-weight: 300;
  src: url('./fonts/Gilroy-Light.woff2') format('woff2');
}
@font-face {
  font-family: Gilroy;
  font-weight: 400;
  src: url('./fonts/gilroy-regular-webfont.woff2') format('woff2');
}
@font-face {
  font-family: Gilroy;
  font-weight: 500;
  src: url('./fonts/gilroy-medium-webfont.woff2') format('woff2');
}
@font-face {
  font-family: Gilroy;
  font-weight: 600;
  src: url('./fonts/gilroy-semibold-webfont.woff2') format('woff2');
}
@font-face {
  font-family: Gilroy;
  font-weight: 800;
  src: url('./fonts/Gilroy-Extrabold.woff2') format('woff2');
}

@font-face {
  font-family: Involve;
  font-weight: 400;
  src: url('./fonts/involve-regular-webfont.woff2') format('woff2');
}
@font-face {
  font-family: Involve;
  font-weight: 500;
  src: url('./fonts/involve-medium-webfont.woff2') format('woff2');
}