@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  overflow: hidden;
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Involve;
  font-size: 40px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: left;  
  margin-bottom: 40px;
  color: $beige;
  background: linear-gradient(90.37deg, #EAEAEA 55.69%, #848484 102.95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;

  @media (max-width: $tablet) {
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
    margin-bottom: 30px;
  }

  @media (max-width: $mobile) {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    margin-bottom: 20px;
  }
}

.content {
  width: 100%;
  margin-bottom: 120px;
  border: 0.5px solid #FFFFFF;
  border-radius: 30px;

  @media (max-width: $tablet) {
    margin-bottom: 100px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 60px;
    border-radius: 10px;
    border: 0.35px solid #FFFFFF;
  }
}

.text {
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.03em;
  color: $beige;
  background: linear-gradient(90.37deg, #EAEAEA 55.69%, #848484 102.95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-family: Involve;
  

  @media (max-width: $tablet) {
    font-size: 28px;
    font-weight: 400;
    line-height: 33.6px;
    letter-spacing: -0.03em;
  }

  @media (max-width: $mobile) {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.03em;
  }
}

.accordionItemWrap {
  position: relative;

  &:not(:last-child) {
    border-bottom: 0.5px solid #FFFFFF;

    @media (max-width: $mobile) {
      border-bottom: 0.35px solid #FFFFFF;
    }
  }
}

.accordionItem {
  width: 100%;

  .accordionAnswer {
    opacity: 0;
  }
}

.accordionItemOpened {
  position: relative;

  .accordionAnswer {
    opacity: 1;
  }
}

.arrowIcon {
  position: absolute;
  right: 50px;
  bottom: 40px;
  z-index: -1;
  width: 20px;
  height: auto;
  transform: rotate(90deg);
  transition: transform .6s ease;

  @media (max-width: $tablet) {
    right: 36px;
    bottom: 27px;
  }

  @media (max-width: $mobile) {
    width: 14px;
    bottom: 10px;
    right: 10px;
  }
}

.arrowIconOpened {
  transform: none;
}

.accordionItemTrigger {
  display: flex;
  width: 100%;
}

.accordionItemTriggerText {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 36px 50px;
  cursor: pointer;

  @media (max-width: $tablet) {
    padding: 27px 36px;
  }

  @media (max-width: $mobile) {
    padding: 10px;
    padding-right: 39px;
  }
}

.accordionItemId {
  font-size: 30px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: -0.03em;
  margin-right: 32px;

  @media (max-width: $tablet) {
    font-size: 18px;
    font-weight: 300;
    line-height: 16.2px;
    margin-right: 30px;    
  }

  @media (max-width: $mobile) {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 300;
    line-height: 12.6px;
  }
}

.accordionItemQuestion {
  @include blockText;
}

.accordionAnswer {
  @include blockText;
  padding: 0 100px 30px 50px;
  transition: opacity .3s ease;

  @media (max-width: $tablet) {
    padding-left: 36px;
  }

  @media (max-width: $mobile) {
    padding: 0 40px 10px 10px;
  }
}