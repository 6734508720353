@import '../../../../../../styles/mixins.scss';

.bottomBlockWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 32px;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
    gap: 30px;
  }
}

.buttonWrapper {
  display: flex;
  gap: 32px;

  @media (max-width: $tablet) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

.tokenPriceWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include profile-bold-text-large;
  color: $white;

  @media (max-width: $mobile) {
    padding: 0 50px;
  }
}

.buyTokenButton {
  @include profile-bold-text-common;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;
  border-radius: 5px;
  min-width: 200px;
  padding: 20px;

  outline: none;

  @media (max-width: $tablet) {
    width: 100%;
    min-width: none;
  }

  &:disabled {
    opacity: .3;
    cursor: default;
  }
}