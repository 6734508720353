@import "../../../../../../styles/mixins.scss";

.wrapper {
  width: 100%;
  padding: 46px 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 93px;
  border-radius: 10px;
  background-color: $background-wnite-alpha01;

  @media (max-width: $tablet) {
    width: unset;
  }
}

.header {
  display: flex;
  gap: 8px;

  @include profile-bold-text-large;
  color: $white;
}

.headerImage {
  width: 110px;
  height: 60px;
  margin: -19px 0;
}

.balanceTable {
  display: flex;
  gap: 30px;
  width: 100%;
  @include profile-bold-text-common;
  color: $white;
}

.balanceTableColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:first-child {
    width: 100%;
  }
}

.balanceTableValue {
  text-align: right;
}
