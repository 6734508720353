@import '../../../../../styles//mixins.scss';

.wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.subWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.block {
  display: flex;
  flex-direction: column;
  background-color: $background-wnite-alpha01;
  border-radius: 5px;
  padding: 20px;
  max-width: 18vw;

  @media (max-width: $tablet) {
    padding: 15px;
    max-width: 35vw;
  }

  @media (max-width: $mobile) {
    padding: 10px;
    max-width: calc(100vw - 36px);
  }
}

.blockHeader {
  color: $white;
  @include profile-bold-text-common;
  margin-bottom: 15px;
}

.headerCenterOnTablet {
  @media (max-width: $tablet) {
    margin: 0 auto 15px;
  }
}

.partnerLinkWrapper {
 display: flex;
 width: 100%;
 justify-content: space-between; 
}

.partnerLink {
  color: $white;
  @include profile-slim-text-common;
  margin-right: 10px;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  overflow: hidden;  
}

.partnerLinkButton {
  width: 24px;
  height: 24px;
}

.button {
  color: rgba(185, 185, 185, 1);
  border: 1px solid rgba(185, 185, 185, 1);
  border-radius: 50px;
  padding: 10px;
  @include profile-slim-text-common;

  @media (max-width: $tablet) {
    margin: auto;
  }
}

.nextLevelWrapper {
  display: flex;
  flex-direction: column;
  background-color: $background-wnite-alpha01;
  border-radius: 5px;
  padding: 20px;

  @media (max-width: $tablet) {
    padding: 15px;
    height: fit-content;
  }

  @media (max-width: $mobile) {
    padding: 10px;
  }
}

.nextLevelItemWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
}

.nextLevelItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.nextLevelItemHeader {
  font-size: 10px;
  font-weight: 300;
  line-height: 9px;
  letter-spacing: -0.03em;
  color: $white;
}

.nextLevelItemBar {
  height: 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-wnite-alpha01;
  border-radius: 1px;
  overflow: hidden;
}

.nextLevelItemBarText {
  font-size: 12px;
  font-weight: 800;
  line-height: 10.8px;
  letter-spacing: -0.03em;
  z-index: 10;
  text-shadow: 0 0 5px black;
}

.nextLevelItemBarContent {
  position: absolute;
  background-color: rgba(17, 239, 17, 1);
  width: 0;
  top: 0;
  left: 0;
  height: 100%;
}
