@import "../../../../styles/mixins.scss";

.wrapper {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tableWrapper {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 5px; /* ширина scrollbar */
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
  }
}

.table {
  display: flex;
  flex-direction: column;
  min-width: 700px;
}

.tableHeader {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.tableRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 15px 0;
}

.tableCell {
  @include profile-slim-text-common;
  color: $white;
  text-overflow: ellipsis;
  overflow: hidden;

  a {
    color: #11ef11;

    &:hover {
      text-decoration: underline;
    }
  }
}

.showMoreButton {
  color: rgba(185, 185, 185, 1);
  border: 1px solid rgba(185, 185, 185, 1);
  background-color: transparent;
  @include profile-bold-text-common;
  padding: 6.5px 39px;
  margin: auto;
  border-radius: 20px;
}

.settingsButton {
  float: right;
}
