@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  overflow: hidden;
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  width: 100%;
  max-width: 926px;
  font-family: Involve;
  font-size: 70px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -0.03em;
  text-align: center;  
  color: $beige;
  background: linear-gradient(94.16deg, #EAEAEA 21.04%, #848484 101.53%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin-bottom: 60px;

  @media (max-width: $tablet) {
    font-size: 42px;
    font-weight: 400;
    line-height: 37.8px;
    letter-spacing: -0.03em;
    margin-bottom: 40px;
  }

  @media (max-width: $mobile) {
    font-size: 24px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: -0.03em;
  }
}

.content {
  width: 100%;
  max-width: 1095px;
  display: grid;
  gap: 45px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr max-content 1fr;
  grid-template-areas: 'card1 card2 card3' '. link .' 'card4 card5 card6';

  @media (max-width: $tablet) {
    gap: 30px;
    max-width: 776px;
  }

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    gap: 10px;
    grid-template-areas: 'card1' 'card2' 'card3' 'card4' 'card5' 'card6' 'link';
  }
}

.card {
  width: 100%;
}

.card1 {
  grid-area: card1;
  transform: translateY(70px);

  @media (max-width: $tablet) {
    transform: translateY(40px);
  }

  @media (max-width: $mobile) {
    transform: none;
  }
}

.card2 {
  grid-area: card2;
}

.card3 {
  grid-area: card3;
  transform: translateY(70px);

  @media (max-width: $tablet) {
    transform: translateY(40px);
  }

  @media (max-width: $mobile) {
    transform: none;
  }
}

.card4 {
  grid-area: card4;
  transform: translateY(-70px);

  @media (max-width: $tablet) {
    transform: translateY(-40px);
  }

  @media (max-width: $mobile) {
    transform: none;
  }
}

.card5 {
  grid-area: card5;
}

.card6 {
  grid-area: card6;
  transform: translateY(-70px);

  @media (max-width: $tablet) {
    transform: translateY(-40px);
  }

  @media (max-width: $mobile) {
    transform: none;
  }
}

.cardInner {
  @include blockText;
  background: linear-gradient(138.01deg, rgba(217, 217, 217, 0.1) 5.39%, rgba(71, 71, 71, 0.1) 94.74%);
  border-radius: 30px;
  aspect-ratio: 1;
  padding: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: calc(100% - 65px);
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: $tablet) {
      width: calc(100% - 52px);
    }

    @media (max-width: $mobile) {
      width: 198px;
    }
  }

  &::before {
    background-image: url(./images/line_l.svg);
    top: 0;

    @media (max-width: $mobile) {
      left: 30px;
      transform: none;
    }
  }

  &::after {
    background-image: url(./images/line_r.svg);
    bottom: 0;

    @media (max-width: $mobile) {
      left: auto;
      right: 30px;
      transform: none;
    }
  }

  @media (max-width: $tablet) {
    padding: 20px;
  }

  @media (max-width: $mobile) {
    aspect-ratio: auto;
    border-radius: 10px;
    padding: 15px 20px;
    justify-content: flex-start;
  }
}

.linkBlock {
  grid-area: link;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  @media (max-width: $tablet) {
    padding: 0;
  }

  @media (max-width: $mobile) {
    padding-top: 10px;
  }
}

.link {
  padding: 25px 35px;
  border-radius: 100px;
  border: 1.37px solid #FFFFFF;
  color: #fff;
  font-size: 40px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: center;  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @media (max-width: $tablet) {
    padding: 15px 20px;
    border: 0.5px solid #FFFFFF;
    font-size: 28px;
    font-weight: 300;
    line-height: 25.2px;
  }

  @media (max-width: $mobile) {
    padding: 18.5px;
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    line-height: 12.6px;
    letter-spacing: -0.03em;
    border-radius: 50px;
  }
}

.title,
.content {
  opacity: 0;
}