@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: blur(10px);
  transition: opacity .3s ease, visibility .3s ease;
}

.wrapVisible {
  opacity: 1;
  visibility: visible;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 75px;
  overflow-y: auto;

  @media (max-width: $tablet) {
    padding: 40px;
  }

  @media (max-width: $mobile) {
    padding: 10px;
  }
}

.content {
  width: 100%;
  border: 0.5px solid #FFFFFF;
  border-radius: 30px;
  position: relative;
  height: max-content;
  background-color: $black;

  @media (max-width: $tablet) {
    border-radius: 20px;
  }

  @media (max-width: $mobile) {
    border-radius: 10px;
  }
}

.closeBtn {
  position: absolute;
  display: flex;
  top: 30px;
  right: 30px;
  z-index: 1;

  @media (max-width: $tablet) {
    top: 20px;
    right: 20px;
  }

  @media (max-width: $mobile) {
    top: 15px;
    right: 15px;
  }
}

.closeIcon {
  width: 40px;
  height: auto;

  @media (max-width: $tablet) {
    width: 30px;
  }

  @media (max-width: $mobile) {
    width: 20px;
  }
}