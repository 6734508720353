@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  overflow: hidden;

  @media (max-width: $mobile) {
    padding-top: 150px;
  }
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  font-family: Involve;
  font-size: 70px;
  font-weight: 400;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  align-self: flex-end;
  margin-bottom: 60px;

  @media (max-width: $tablet) {
    font-size: 42px;
    margin-bottom: 40px;
  }

  @media (max-width: $mobile) {
    font-size: 24px;
    font-weight: 400;
    line-height: 21.6px;
  }
}

.titleTop,
.titleBottomText {
  color: $beige;
  background: linear-gradient(90.37deg, #EAEAEA 55.69%, #848484 102.95%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleBottom {
  margin-top: -20px;

  @media (max-width: $tablet) {
    margin-top: -15px;
  }

  @media (max-width: $mobile) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
}

.titleAccent {
  color: $green;
}

.graph {
  width: 100vw;
  height: auto;

  @media (max-width: $mobile) {
    display: none;
  }
}

.graphMobile {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    margin-top: -66px;
  }
}

.numbers {
  margin-top: -107px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 997px;
  justify-content: space-between;

  @media (max-width: $tablet) {
    margin-top: -82px;
    max-width: 750px;
  }

  @media (max-width: $mobile) {
    margin-top: 30px;
    flex-direction: column;
  }
}

.numberItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;  

  &:not(:last-child) {

    @media (max-width: $tablet) {
      margin-right: 80px;
    }

    @media (max-width: $mobile) {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
}

.numberValue {
  font-size: 70px;
  font-weight: 300;
  line-height: 70px;
  letter-spacing: -0.03em;
  margin-bottom: 15px;
  color: $beige;

  @media (max-width: $tablet) {
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 300;
    line-height: 45px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 5px;
  }
}

.numberLabel {
  @include blockText;

  @media (max-width: $mobile) {
    font-size: 16px;
    font-weight: 300;
    line-height: 17.6px;
    letter-spacing: -0.03em;
    text-align: center;    
  }
}

.titleTop,
.titleBottom,
.graphWrap,
.numbers {
  opacity: 0;
}