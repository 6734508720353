@import '../../../../styles/mixins.scss';

.wrapper {
  display: flex;
  padding-top: 20px;
  gap: 40px;
  
  @media (max-width: $mobile) {
    flex-direction: column;
    gap: 20px;
  }
}

.formBlockWrapper {
  width: 100%;
  display: flex;
  gap: 40px;

  @media (max-width: $tablet) {
    flex-direction: column;
    gap: 80px;
  }

  @media (max-width: $mobile) {
    gap: 64px;
  }
}

.photoWrapper {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  padding: 24px;
  border-radius: 5px;
  background-color: $background-wnite-alpha01;
  display: flex;

  @media (max-width: $mobile) {
    margin: auto;
  }
}

.photoPlaceHolder {
  background-color: rgba($color: #0E0E0E, $alpha: .5);
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.uploadPhotoBtn {
  padding: 6.5px 17px;
  color: $beige2;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 300;
  line-height: 10.8px;
  letter-spacing: -0.03em;
  border: 1px solid rgba(185, 185, 185, 1);
}

.formBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.formBlockHeader {
  @include profile-bold-text-large;
  color: $white;
}

.formBlockInput {
  padding-bottom: 10px;
  @include profile-slim-text-common;
  color: $white;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;

  &:disabled {
    background-color: rgba($color: #FFF, $alpha: .1);
  }
}

.submitBtn {
  color: rgba(185, 185, 185, 1);
  border: 1px solid rgba(185, 185, 185, 1);
  background-color: transparent;
  @include profile-bold-text-common;
  padding: 6.5px 39px;
  margin-left: 10px;
  margin-top: 10px;
  width: fit-content;
  border-radius: 20px; 
}