@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 170px;

  @media (max-width: $tablet) {
    padding-top: 150px;
  }
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
  min-height: 700px;
}

.title {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Involve;
  opacity: 0;
}

.titleTop {
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.03em;
  text-align: left;  
  margin-bottom: 5px;
  color: $beige;
  background: linear-gradient(90deg, #848484 0%, #EAEAEA 65.6%);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;

  @media (max-width: $tablet) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 12.6px;
    text-align: right;
    padding-right: 191px;
  }
}

.titleBottom {
  color: $beige;
  text-transform: uppercase;
  font-size: 70px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: -0.03em;
  padding-right: 50px;
  align-self: center;

  @media (max-width: $tablet) {
    font-size: 64px;
    line-height: 64px;
  }

  @media (max-width: $mobile) {
    font-size: 24px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: right;
    align-self: flex-end;
    padding-right: 0;
  }
  
}

.content {
  width: 100%;
  position: relative;
  max-width: 1250px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tokenImage {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 450px;
  align-self: center;
  right: 50%;
  opacity: 0;
  transform: scale(3) translate(20%);
  bottom: 15px;

  @media (max-width: $mobile) {
    position: static;
    width: 300px;
    height: auto;
    margin: 60px 0;
  }
}

.firstContent {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-top: 67px;
  margin-right: -280px;

  @media (max-width: $tablet) {
    margin-right: 0;
    align-self: flex-start;
  }

  @media (max-width: $mobile) {
    margin-top: 0;
    padding-left: 0;
    align-self: center;
    width: 100%;
  }
}

.firstBlockTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding-right: 150px;

  @media (max-width: $tablet) {
    padding-right: 0;
    padding-left: 170px;
  }

  @media (max-width: $mobile) {
    padding-left: 0;
    width: 100%;
    align-items: center;
    margin-bottom: 30px;
  }
}

.firstBlockTopText,
.firstBlockBottomText {
  @include blockText;
  max-width: 347px;
  text-align: justify;

  @media (max-width: $tablet) {
    max-width: 280px;
  }

  @media (max-width: $mobile) {
    max-width: 200px;
  }
}

.firstBlockTopLine {
  margin-top: -40px;
  margin-left: 123px;
}

.firstBlockBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: -280px;
  align-self: flex-start;

  @media (max-width: $tablet) {
    margin-left: 0;
  }

  @media (max-width: $mobile) {
    width: 100%;
    align-items: flex-start;
  }
}

.firstBlockTopLine,
.firstBlockBottomLine {

  @media (max-width: $mobile) {
    height: auto;
    width: 100%;
    max-width: 200px;
    margin-left: 0;
  }
}

.firstBlockBottomLine {
  margin-bottom: -40px;
  margin-left: 123px;
}

.firstBlockTopLine,
.firstBlockBottomLine {

  @media (max-width: $mobile) {
    height: auto;
    width: 100%;
    max-width: 200px;
    margin-left: 0;
    transform: translateX(50%);
  }
}

.firstBlockTopText,
.firstBlockBottomText,
.firstBlockTopLine,
.firstBlockBottomLine {
  opacity: 0;
}

.finalText {
  @include blockText;
  position: absolute;
  padding-left: 480px;
  text-align: justify;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  opacity: 0;

  @media (max-width: $mobile) {
    padding-left: 0;
    top: 420px;
    right: 0;
    left: 0;
    transform: none;
  }
}