@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 800;
  font-size: 16px;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  color: $beige2;
  border: 0.5px solid #FFFFFF;
  padding: 7px 38px;
  border-radius: 30px;
  transition: background-color .3s ease, border .3s ease, color .3s ease;

  &:disabled,
  &:active {
    border-color: $gray;
    color: $gray;
  }
}
