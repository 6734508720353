@import "variables.scss";

body {
  font-family: Gilroy;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $black;
  color: $beige2;
  padding: 0;
  margin: 0;
  background-image: url(./background.webp);
  background-size: 100% auto;

  @media (max-width: $tablet) {
    background-image: url(./background_t.webp);
  }

  @media (max-width: $mobile) {
    background-image: url(./background_m.webp);
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  object-fit: contain;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

input,
select,
textarea,
button {
  font: inherit;
  outline: none;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lock-body {
  overflow: hidden;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}