@import '../../../../../styles/mixins.scss';

.modal {
  border: none;
  max-width: 1082px;
}

.content {
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width: $mobile) {
    overflow: auto;
  }
}

.screenshot {
  width: 100%;
  height: auto;
  object-fit: contain;

  @media (max-width: $mobile) {
    min-width: 700px;
  }
}

.link {
  position: absolute;
  border: 0.5px solid rgba(255, 255, 255, 1);
  padding: 11px 40px;
  font-size: 14px;
  font-weight: 300;
  line-height: 12.6px;
  letter-spacing: -0.03em;
  border-radius: 50px;
  right: 27px;
  bottom: 16px;

  @media (max-width: $tablet) {
    padding: 8px 32px;
    right: 14px;
    bottom: 12px;
  }

  @media (max-width: $mobile) {
    right: 15px;
    bottom: 15px;
    padding: 5px 18px;
  }
}