@import '../../../../../styles/mixins.scss';

.wrapper {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background-color: $background-wnite-alpha01;
  padding: 30px;

  @media (max-width: $tablet) {
    padding: 0 20px;
    padding: 20px;
  }

  @media (max-width: $mobile) {
    padding: 0;
    flex-direction: column;
    gap: 10px;
    background-color: unset;
  }
}

.subWrapper {
  display: flex;
  justify-content: space-around;

  @media (max-width: $mobile) {
    padding: 22px 12px;
    background-color: $background-wnite-alpha01;
    border-radius: 5px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90px;
}

.itemText {
  color: $white;
  font-size: 16px;
  font-weight: 800;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  text-align: center;
}

.divider {
  width: 60px;
  height: 5px;
  border-radius: 1px;
  background: rgba(17, 239, 17, 1);
  margin: 17px 0 14px;
}