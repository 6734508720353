@import '../../../../../styles/mixins.scss';

.container {
  width: 100%;
  // border: 1px solid red;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 5px;               /* ширина scrollbar */
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;        /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey;    /* цвет плашки */
    border-radius: 20px;       /* закругления плашки */
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 10px;
  min-width: 1000px;
}

.levelSelectWrapper {
  display: flex;
  font-size: 16px;
  font-weight: 800;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  color: $white;
  align-items: center;
  gap: 20px;
}

.levelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.levelLowerStroke {
  width: 30px;
  height: 5px;
  border-radius: 1px;
  background: rgba(17, 239, 17, 1);
}

.table {
  display: flex;
  flex-direction: column;
}

.tableHeader {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 300;
  line-height: 12.6px;
  letter-spacing: -0.03em;
  text-align: left;
  color: $white;
  display: flex;
}


.tableUpLeftCell {
  max-width: 350px;
  min-width: 350px;
  padding: 0 20px 30px 20px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  border-right: 0.5px solid rgba(255, 255, 255, 0.5);
  color: $white;
}

.tableUpCell {
  width: 10%;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  padding: 0 20px 30px 20px;
  color: $white;
  flex-grow: 1;
}

.tableRow {
  display: flex;
}

.tableFirstCell {
  max-width: 350px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  border-right: 0.5px solid rgba(255, 255, 255, 0.5);
  color: $white;
}

.tableCell {
  width: 10%;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  padding: 35px 20px 35px 20px;
  color: $white;
  flex-grow: 1;
}

.showMoreButton {
  color: rgba(185, 185, 185, 1);
  border: 1px solid rgba(185, 185, 185, 1);
  background-color: transparent;
  @include profile-bold-text-common;
  padding: 6.5px 39px;
  margin: auto;
  border-radius: 20px;
}

.cellTop {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cellBottom {
  display: flex;
  align-items: center;
  gap: 10px;
    margin-left: 10px;
  margin-top: 10px;
}

.tgButton {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.cellLevel {
  font-size: 14px;
  font-weight: 300;
  line-height: 12.6px;
  letter-spacing: -0.03em;
}

.cellImageWrapper {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: rgba(217, 217, 217, 1);
}

.cellPartnerName {
  font-size: 14px;
  font-weight: 800;
  line-height: 12.6px;
  letter-spacing: -0.03em;  
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellPartnerStatus {
  background-color: rgba(55, 231, 55, 1);
  padding: 3px 11px;
  font-size: 10px;
  font-weight: 300;
  line-height: 9px;
  letter-spacing: -0.03em;
  color: rgba(0, 0, 0, 1)
}

.cellPartnerButton {
  color: rgba(185, 185, 185, 1);
  border: 1px solid rgba(185, 185, 185, 1);
  background-color: transparent;
  @include profile-bold-text-common;
  padding: 6.5px 39px;
  width: fit-content;
  border-radius: 20px; 
}