@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  overflow: hidden;
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerTop {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 45px;  

  @media (max-width: $tablet) {
    margin-bottom: 20px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.footerItem {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.itemTitle {
  font-size: 15px;
  font-weight: 800;
  line-height: 16.5px;
  text-align: left;
  margin-bottom: 12px;

  @media (max-width: $tablet) {
    margin-bottom: 10px;
  }

  @media (max-width: $mobile) {
    font-size: 12px;
    font-weight: 800;
    line-height: 14.7px;
    margin-bottom: 15px;
  }
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 12px;

    @media (max-width: $tablet) {
      margin-bottom: 10px;
    }
  
    @media (max-width: $mobile) {
      margin-bottom: 15px;
    }
  }
}

.listLink {
  font-size: 15px;
  font-weight: 300;
  line-height: 16.5px;

  @media (max-width: $tablet) {
    font-size: 15px;
    font-weight: 300;
    line-height: 16.5px;
  }

  @media (max-width: $mobile) {
    font-size: 12px;
    font-weight: 300;
    line-height: 13.2px;
  }
}

.listLinkDesktop {
  @media (max-width: $mobile) {
    display: none;
  }
}

.listLinkMobile {
  display: none;
  @media (max-width: $mobile) {
    display: block;
    margin-bottom: 10px;
  }
}

.footerBottom {
  padding: 25px 0 10px;
  width: calc(100% - 210px);
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: $tablet) {
    width: 100%;
  }
}

.copyright {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  text-align: center;  

  @media (max-width: $mobile) {
    font-size: 10px;
    font-weight: 300;
    line-height: 11px;  
  }
}