// colors 

$white: #FFFFFF;
$beige: #EAEAEA;
$beige2: #B9B9B9;
$green: #11EF11;
$gray: #484848;
$black: #090909;
$darkGray: #202020;

$background-wnite-alpha01: rgba(255, 255, 255, 0.1);

$textGradient: linear-gradient(94.16deg, #EAEAEA 21.04%, #848484 101.53%);


// breakpoints

$tablet: 960px;
$mobile: 767px;