@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@keyframes levitating {
  0% {
    transform: translateY(-10px);
  }

  100%  {
    transform: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100%  {
    opacity: 1;
  }
}

.wrap {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 60px 0 50px;

  @media (max-width: $tablet) {
    padding: 40px 0 100px;
  }

  @media (max-width: $mobile) {
    padding-top: 60px;
    padding-bottom: 26px;
  }
}

.inner {
  @include container;
  padding-top: 110px;
  position: relative;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.contentTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 340px;

  @media (max-width: $tablet) {
    margin-bottom: 360px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.limeText {
  margin-left: 36px;
  width: 57%;
  display: flex;
  height: auto;
  opacity: 0;

  @media (max-width: $tablet) {
    margin-left: 0;
    width: 62%;
  }

  @media (max-width: $mobile) {
    width: 100%;
    margin-bottom: 267px;
  }
}

.text {
  @include blockText;
  text-align: justify;
}

.textTop {
  width: 34%;
  opacity: 0;

  @media (max-width: $tablet) {
    width: 44%;
  }

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.contentBottom {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 658px;
  position: relative;
  z-index: 2;
  opacity: 0;

  @media (max-width: $tablet) {
    align-items: flex-start;
    max-width: 585px;
  }

  @media (max-width: $mobile) {
    flex-direction: row-reverse;
    max-width: none;
    align-items: center;
  }
}

.arrowIcon {
  width: 72px;
  height: auto;
  margin-bottom: 2px;
  margin-left: 20px;
  flex-shrink: 0;

  @media (max-width: $tablet) {
    margin-left: 30px;
    width: 50px;
    margin-bottom: 0;
    margin-top: 4px;
  }

  @media (max-width: $mobile) {
    margin-left: 0;
    margin-right: 20px;
    width: 40px;  
  }
}

.textBottom {
  width: 100%;
  max-width: 567px;
}

.imagesBlock {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 62.5%;
  opacity: 0;

  @media (max-width: 1200px) {
    top: 10%;
  }

  @media (max-width: $tablet) {
    top: 0;
    width: 82%;
    left: 48%;
  }

  @media (max-width: $mobile) {
    width: 100%;
    left: 50%;
    top: 35px;
  }
}

.imagesInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 100%;
}

.limeBlock {
  position: relative;
  width: 40%;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: 167px;
  }
}

.lime {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  animation: levitating 2s linear infinite alternate-reverse;
}

.shadow {
  position: absolute;
  bottom: 0;
  left: -150px;
  width: 316px;
  height: auto;

  @media (max-width: $mobile) {
    width: 227px;
    right: 0;
    left: auto;
    transform: translateX(100%);
    bottom: 40px;
  }
}

.platform {
  width: 100%;
  height: auto;
  margin-top: -190px;

  @media (max-width: $mobile) {
    margin-top: -110px;
  }
}