@import "../../../../../../styles/mixins.scss";

.wrapper {
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: $tablet) {
    gap: 10px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.partnerLinkWrapper {
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 233px;
  width: 100%;
  height: fit-content;
  padding: 20px 20px 26px;
  background-color: $background-wnite-alpha01;
  border-radius: 10px;

  @media (max-width: $mobile) {
    max-width: unset;
    padding: 15px 20px;
  }
}

.partnerLinkHeader {
  @include profile-bold-text-common;
  color: $white;
}

.partnerLinkContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $white;
  @include profile-slim-text-common;
}

.link {
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  overflow: hidden;
}

.qrCodeWrapper {
  width: 100%;
  aspect-ratio: 1;
  background-color: $background-wnite-alpha01;
  border-radius: 10px;
  padding: 20px;
}

.partnerLinkContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buyTokenButtonWrapper {
  @media (min-width: $tablet) {
    display: none;
  }

  @media (max-width: $mobile) {
    display: none;
  }
}