@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding-top: 100px;
  }
}

.inner {
  @include container;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-bottom: 90px;
  font-family: Involve;

  @media (max-width: $tablet) {
    margin-bottom: 66px;
  }

  @media (max-width: $mobile) {
    text-align: left;
    align-items: flex-start;
    margin-bottom: 40px;
  }
}

.titleTop {
  font-size: 70px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -0.03em;
  color: $beige;
  background: linear-gradient(90deg, #848484 0%, #EAEAEA 65.6%);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
  opacity: 0;

  @media (max-width: $tablet) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (max-width: $mobile) {
    font-size: 24px;
    line-height: 21.6px;
  }
}

.titleBottom {
  font-size: 40px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: $beige;
  background: linear-gradient(90deg, #EAEAEA 26.29%, #848484 100%);
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  
  @media (max-width: $tablet) {
    font-size: 30px;
    line-height: 30px;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 12.6px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentItem {
  display: flex;
  align-items: flex-start;
  width: max-content;

  &:not(:last-child) {
    margin-bottom: 40px;
  
    @media (max-width: $tablet) {
      margin-bottom: 25px;
    }

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: $mobile) {
    align-items: center;
  }

  &:first-child {
    transform: translateX(-300%);

    @media (max-width: $tablet) {
      padding-left: 40px;
    }

    @media (max-width: $mobile) {
      padding: 0;
    }
  }

  &:nth-child(2) {
    align-self: flex-end;
    transform: translateX(300%);

    @media (max-width: $tablet) {
      padding-right: 30px;
    }

    @media (max-width: $mobile) {
      padding: 0;
      align-self: flex-start;
      transform: translateX(-300%);
    }
  }

  &:nth-child(3) {
    padding-left: 225px;
    transform: translateX(-300%);

    @media (max-width: $tablet) {
      padding-left: 118px;
    }

    @media (max-width: $mobile) {
      padding: 0;
    }
  }

  &:nth-child(4) {
    align-self: flex-end;
    padding-right: 147px;
    transform: translateX(300%);

    @media (max-width: $tablet) {
      padding-right: 187px;
    }

    @media (max-width: $mobile) {
      padding: 0;
      align-self: flex-start;
      transform: translateX(-300%);
    }
  }

  &:last-child {
    transform: translateX(-300%);

    @media (max-width: $tablet) {
      padding-left: 60px;
    }

    @media (max-width: $mobile) {
      padding: 0;
    }
  }
}

.arrowIcon {
  width: 20px;
  height: auto;
  margin-right: 20px;
  
  @media (max-width: $tablet) {
    width: 14px;
    margin-right: 15px;
  }

  @media (max-width: $mobile) {
    margin-right: 10px;
  }
}

.contentItemText {
  @include blockText;
  display: flex;  
  flex-direction: column;
  text-align: justify;
}

.contentAccent {
  text-align: left;
  text-transform: uppercase;
  color: $green;
}