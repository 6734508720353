@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 104px;

  @media (max-width: $tablet) {
    padding-bottom: 13px;
    padding-top: 14px;
  }

  @media (max-width: $mobile) {
    
  }
}

.inner {
  @include container;
  padding-top: 174px;

  @media (max-width: $tablet) {
    padding-top: 0;
  }
}

.lineBlock {
  position: absolute;
  display: flex;
  left: 10px;
  top: 0;

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.lineInner {
  display: flex;
  position: relative;

  @media (max-width: $mobile) {
    width: 100%;
  }
}

.spark {
  position: absolute;
  bottom: 60%;
  left: 0;
  width: 75px;
  height: auto;
  transform: translateX(-50%);
  opacity: 0;

  @media (max-width: $tablet) {
    width: 50px;
  }
}

.line {
  opacity: 0;

  @media (max-width: $tablet) {
    width: 472px;
    height: auto;
  }
}

.contentBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: $tablet) {
    padding-top: 120px;
  }

  @media (max-width: $mobile) {
    padding-top: 100px;
  }
}

.text {
  @include blockText;
  width: 100%;
  padding-left: 411px;

  margin-bottom: 30px;
  opacity: 0;

  @media (max-width: $tablet) {
    padding-left: 306px;
    margin-bottom: 20px;
    text-align: justify;
  }

  @media (max-width: $mobile) {
    padding-left: 108px;
  }
}

.textFirst {
  @include blockText;
  position: absolute;
  bottom: 0;
  left: 200px;
  transform: translateY(50%);
  opacity: 0;

  @media (max-width: $mobile) {
    left: 110px;
  }
}

.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;

  @media (max-width: $mobile) {
    gap: 5px;
    justify-content: center;
  }
}

.item {
  font-size: 25px;
  font-weight: 300;
  line-height: 22.5px;
  letter-spacing: -0.03em;
  padding: 9px 20px;
  border: 0.5px solid $white;
  border-radius: 50px;
  opacity: 0;

  @media (max-width: $tablet) {
    font-size: 17.5px;
    line-height: 15.75px;
    padding: 5px 15px;
  }

  @media (max-width: $mobile) {
    padding: 6px 15px;
    font-size: 12px;
    line-height: 10.8px;
  }
}

.line,
.spark {

  @media (max-width: $mobile) {
    display: none;
  }
}

.lineMobile,
.sparkMobile {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    opacity: 0;
  }
}

.sparkMobile {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 40px;
  height: auto;
  transform: translateX(-50%);
  opacity: 0;
}