@import '../../../../../../styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  aspect-ratio: 1.5;

  @media (max-width: $mobile) {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.buttonsWrapper {
  @media (max-width: $tablet) {
    display: none;
  }

  @media (max-width: $mobile) {
    display: unset;
  }
}