@import "../../../../../styles/mixins.scss";

.modalContent {
  background-color: $background-wnite-alpha01;
  border-radius: 4px;
  border: none;
  padding: 32px;
  width: 100%;
  max-width: 520px;
  color: $white;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  color: $white;  

  @media (max-width: $mobile) {
    font-size: 16px;
    font-weight: 800;
    line-height: 14.4px;
    margin-bottom: 75px;
  }
}

.headerTitle {
  font-weight: 800;
  margin-bottom: 8px;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }
}

.wallet {
  font-weight: 300;
  max-width: calc(100% - 50px);
  font-size: 18px;
  word-break: break-all;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: $mobile) {
    margin-bottom: 5px;
  }
}

.valueBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.valueTitle {
  font-size: 20px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: -0.03em;
  margin-bottom: 8px;
}

.valueInput {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 117px;
  border: 0.8px solid #FFFFFF;
  color: $white;
  border-radius: 8px;
  font-size: 32px;
  font-weight: 800;
  line-height: 35.2px;
  text-align: center;  
  background-color: transparent;

  @media (max-width: $mobile) {
    border-radius: 5px;
  }
}

.available {
  max-width: 122px;
  text-align: right;
  align-self: flex-end;
  font-size: 12.8px;
  font-weight: 800;
  line-height: 12.8px;
  letter-spacing: -0.03em;
  text-align: right;  
  margin-bottom: 10px;
}

.tokenInfo {
  border: 0.5px solid #FFFFFF;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 18px 16px;
  margin-bottom: 5px;

  @media (max-width: $mobile) {
    border-radius: 5px;
    padding: 16px;
  }
}

.tokenInfoLine {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 22px;
  }
}

.tokenInfoQntTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  font-family: Involve;
}

.tokenInfoQnt {
  font-size: 16px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: -0.03em;
}

.tokenInfoValueTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
}

.tokenInfoValue {
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.03em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.convertValue {
  font-size: 12px;
  font-weight: 400;
  line-height: 10.8px;
  letter-spacing: -0.03em;
  text-align: right;  
  margin-top: 11px;
}

.submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border: 0.5px solid #FFFFFF;
  color: $green;
  font-size: 19.01px;
  font-weight: 600;
  line-height: 17.11px;
  letter-spacing: -0.03em;
  text-align: center;  
  border-radius: 8px;

  @media (max-width: $mobile) {
    border-radius: 5px;
  }
}

.agree {
  font-size: 12.8px;
  font-weight: 400;
  line-height: 11.52px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}