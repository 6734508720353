@import "./variables.scss";

@mixin headerCard {
  width: 100%;
  border-radius: 5px;
  padding: 13px 50px;
  background-color: rgba(255, 255, 255, 0.1);

  @media (max-width: $tablet) {
    padding: 15px 29px;
  }

  @media (max-width: $mobile) {
    padding: 7px 15px;
  }
}

@mixin blockText {
  font-size: 22px;
  font-weight: 300;
  line-height: 24.2px;
  letter-spacing: -0.03em;

  @media (max-width: $tablet) {
    font-size: 18px;
    line-height: 19.8px;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 15.4px;
  }
}

@mixin container {
  width: 100%;
  max-width: 1310px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
}

@mixin profile-bold-text-common {
  font-size: 16px;
  font-weight: 800;
  line-height: 14.4px;
  letter-spacing: -0.03em;

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 12.6px;
  }
}

@mixin profile-bold-text-large {
  font-size: 24px;
  font-weight: 800;
  line-height: 21.6px;
  letter-spacing: -0.03em;

  @media (max-width: $mobile) {
    font-size: 18px;
    line-height: 16.2px;
  }
}

@mixin profile-slim-text-common {
  font-size: 16px;
  font-weight: 300;
  line-height: 14.4px;
  letter-spacing: -0.03em;

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 14.4px;
  }
}