@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 120px;
  overflow: hidden;
  
  @media (max-width: $tablet) {
    padding-top: 75px;
  }

  @media (max-width: $mobile) {
    padding-top: 43px;
  }
}

.inner {
  @include container;
  padding-top: 60px;
  opacity: 0;
}

.title {
  width: 100%;
  text-align: right;
  padding-left: 300px;
  font-family: Involve;
  font-size: 70px;
  font-weight: 500;
  line-height: 63px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  color: $beige;

  @media (max-width: $tablet) {
    font-size: 42px;
    line-height: 42px;
  }

  @media (max-width: $mobile) {
    padding-left: 0;
    font-size: 24px;
    line-height: 21.6px;
  }
}

.navButton {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 70px;
  z-index: 3;
  
  @media (max-width: $tablet) {
    width: 30px;
  }

  @media (max-width: $mobile) {
    height: calc(100% - 135px);
    bottom: auto;
  }
}

.navButtonLeft {
  left: 0;

  @media (max-width: $tablet) {
    justify-content: flex-start;
  }
}

.navButtonRight {
  right: 0;
  
  @media (max-width: $tablet) {
    justify-content: flex-end;
  }
}

.slider {
  display: flex;
  position: relative;
  width: 100%;
}

.sliderContent {
  position: relative;
  width: 100%;
  height: 803px;
  margin-top: -220px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 43px;
  padding-bottom: 90px;

  @media (max-width: $tablet) {
    height: 572px;
    margin-top: -130px;
  }

  @media (max-width: $mobile) {
    height: auto;
    margin-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.slide {
  position: absolute;
  width: 100%;
  max-width: 508px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
  opacity: 0;

  @media (max-width: $tablet) {
    max-width: 360px;
  }

  @media (max-width: $mobile) {
    max-width: none;
    padding: 0 54px;
    bottom: 24%;
    left: 100%;
    transition: all .7s ease;
  }
}

.slideActive {
  opacity: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: $mobile) {
    bottom: 24%;
  }
}

.slideNext {
  transform: none;
  bottom: 50%;
  left: 80%;

  @media (max-width: $mobile) {
    transform: none;
    bottom: 24%;
    left: 100%;
  }
}

.slidePrev {
  opacity: .4;
  transform: scale(.45);
  left: -2%;
  bottom: 29%;
  
  @media (max-width: $tablet) {
    left: -1%;
    bottom: 22%;
  }

  @media (max-width: $mobile) {
    transform: none;
    bottom: 24%;
    left: -100%;
  }
}

.slidePrevSecond {
  opacity: .1;
  transform: scale(.3);
  left: -14%;
  bottom: 56%;
  
  @media (max-width: $tablet) {
    left: -12%;
    bottom: 53%;
  }

  @media (max-width: $mobile) {
    transform: none;
    bottom: 30%;
    opacity: 1;
    left: -100%;
  }
}

.slidePrevHidden {
  opacity: 0;
  transform: scale(0);
  left: -14%;
  bottom: 56%;

  @media (max-width: $mobile) {
    transform: none;
    bottom: 30%;
    left: -100%;
  }
}

.slideImage {
  width: 100%;
  height: auto;
}

.line {
  position: absolute;
  bottom: 16%;
  left: 47%;
  transform: rotate(9deg) translateX(-50%);
  
  @media (max-width: $tablet) {
    width: 769px;
    height: auto;
    bottom: 18%;
  }

  @media (max-width: $mobile) {
    width: 347px;
    bottom: auto;
    top: -12%;
  }
}

.slide_3 .slideImage {
  max-width: 461px;
}

.slide_4 .slideImage {
  max-width: 499px;
}

.slideInfo {
  width: 311px;
  height: 198px;
  position: relative;

  @media (max-width: $tablet) {
    width: 251px;
    height: 141px;
  }

  @media (max-width: $mobile) {
    width: 100%;
    height: 135px;
    margin-top: 372px;
  }
}

.slideInfoInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 1s ease;

  @media (max-width: $mobile) {
    transition: all .7s ease;
  }
}

.slideInfoInnerActive {
  opacity: 1;
}

.infoDate {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 800;
  line-height: 24.2px;
  letter-spacing: -0.03em;
  margin-bottom: 19px;

  @media (max-width: $tablet) {
    font-size: 16px;
    line-height: 14.4px;
    margin-bottom: 15px;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 12.6px;
    
  }
}

.infoText {
  @include blockText;
  text-align: justify;
  margin-bottom: auto;
  transition: all 1s ease;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
    transition: all .7s ease;
  }
}

.projectBtn {
  width: 100%;
  max-width: 214px;
  padding-right: 18px;
  padding-left: 18px;
  transition: all 1s ease;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: none;
    height: 50px;
    transition: all .7s ease;
  }
}

.popupWrap {
  padding: 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet) {
    padding: 44px 30px 30px;
  }

  @media (max-width: $mobile) {
    padding: 43px 10px 35px;
  }
}

.popupTitle {
  font-size: 60px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -0.03em;
  font-family: Involve;
  margin-bottom: 50px;

  @media (max-width: $tablet) {
    font-size: 44px;
    line-height: 39.6px;
  }

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    line-height: 21.6px;
    margin-bottom: 24px;
  }
}

.popupTitleText {
  text-transform: uppercase;
  color: $beige;

  @media (max-width: $mobile) {
    margin-bottom: 12px;
  }
}

.popupTitleDate {
  display: inline-flex;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 21.6px;
  letter-spacing: -0.03em;
  margin-left: 30px;
  color: $white;
  vertical-align: middle;

  .popupSpark {
    margin-right: 15px;

    @media (max-width: $tablet) {
      margin-right: 8px;
    }
  }

  @media (max-width: $tablet) {
    margin-left: 15px;
  }

  @media (max-width: $mobile) {
    margin-left: -10px;
    font-size: 18px;
    line-height: 16.2px; 
  }
}

.popupSpark {
  width: 40px;
  height: auto;
}

.popupText {
  @include blockText;

  &:not(:last-child) {
    margin-bottom: 30px;

    @media (max-width: $tablet) {
      margin-bottom: 20px;
    }
  }
}

.popupTextBold {
  font-weight: 800;
}

.popupBlockWithImage {
  display: flex;

  @media (max-width:$mobile) {
    flex-direction: column-reverse;
  }
}

.blockWithText {
  flex-shrink: 1;
}

.popupImageBugs {
  max-width: 508px;
  height: auto;
  margin-left: 27px;
  margin-top: -53px;
  margin-bottom: -25px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 337px;
  }
}

.popupMiddleCard {
  @include blockText;
  position: relative;
  width: 100%;
  max-width: 758px;
  align-self: center;
  background: linear-gradient(138.01deg, rgba(217, 217, 217, 0.1) 5.39%, rgba(71, 71, 71, 0.1) 94.74%);
  padding: 30px;

  border-radius: 30px;
  margin-bottom: 40px;
  margin-top: 20px;

  @media (max-width: $tablet) {
    border-radius: 10px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 70px);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 2px;
    right: 50%;
    transform: translateX(50%);
  }

  &::before {
    background-image: url(./images/line_left_l.svg);
    top: 0;
  }

  &::after {
    background-image: url(./images/line_right_l.svg);
    bottom: 0;
  }
}

.textAccent {
  color: $green;
}


.popupItem {
  @include blockText;
  display: flex;
  align-items: flex-start;
  max-width: 833px;

  .popupSpark {
    margin-top: -10px;
    margin-left: -13px;
    margin-right: 6px;
  }

  &:not(:last-child) {
    margin-bottom: 14px;

    @media (max-width: $tablet) {
      margin-bottom: 6px;
    }
  }
}

.popupWrapBug {
  .popupItem:not(:last-child) {
    margin-bottom: 30px;
  }
}

.popupImageShampoo {
  max-width: 574px;
  margin-top: -62px;
  margin-right: -49px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 378px;
    margin-right: -32px;
  }
}

.popupListTitle {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  letter-spacing: -0.03em;
  margin-bottom: 12px;
  margin-top: 20px;
}

.popupItemShampoo {
  max-width: none;
}

.popupLargeLink {
  position: relative;
  margin-top: 60px;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  background: linear-gradient(138.01deg, rgba(217, 217, 217, 0.1) 5.39%, rgba(71, 71, 71, 0.1) 94.74%);
  padding: 30px;

  border-radius: 30px;
  font-size: 25px;
  font-weight: 300;
  line-height: 22.5px;
  letter-spacing: -0.03em;

  @media (max-width: $tablet) {
    margin-top: 35px;
    border-radius: 10px;
    padding: 20px 30px;
    font-size: 18px;
    line-height: 16.2px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc(100% - 70px);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 2px;
    right: 50%;
    transform: translateX(50%);
  }

  &::before {
    background-image: url(./images/line_left_s.svg);
    top: 0;
  }

  &::after {
    background-image: url(./images/line_right_s.svg);
    bottom: 0;
  }
}

.popupImagePhone {
  max-width: 550px;
  margin-top: -19px;
  margin-right: -92px;
  margin-bottom: -50px;
  margin-bottom: -100px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 395px;
    margin-top: -106px;
    margin-right: -65px;
  }
}

.popupImageStabilize {
  max-width: 541px;
  margin-top: -53px;
  margin-right: -20px;
  margin-left: -40px;
  padding-left: 20px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 345px;
    margin-top: -37px;
    margin-right: -7px;
  }
}

.popupWrapStabilize {
  .popupList {
    margin-bottom: 40px;
  }
}

.popupImagePowerbank {
  max-width: 432px;
  margin-top: -104px;
  margin-right: -17px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 324px;
  }
}

.popupTextPoint {
  display: flex;
  align-items: center;
}

.pdfIcon {
  width: 39px;
  height: auto;
  margin: -10px 10px -10px 0;
}

.popupHouseImage {
  max-width: 602px;
  margin-right: -50px;
  margin-top: -150px;
  margin-bottom: -150px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 358px;
    margin-right: -9px;
    margin-top: -222px;
  }
}

.popupWrapHouses {
  .popupListTitle {
    margin-top: 50px;
  }
}

.popupImageParaffin {
  max-width: 432px;
  margin-top: -50px;
  flex-shrink: 2;

  @media (max-width: $tablet) {
    max-width: 312px;
  }
}

.popupImageBugs,
.popupImageParaffin,
.popupHouseImage,
.popupImagePowerbank,
.popupImageShampoo,
.popupImageStabilize,
.popupImagePhone {
  @media (max-width: $mobile) {
    max-width: none;
    width: calc(100% - 90px);
    margin: 0 0 10px;
    align-self: center;
  }
}