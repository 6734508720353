@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@keyframes fadeIn {
  0% {
    opacity: 0;
  } 
  100% {
    opacity: 1;
  }   
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 3;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 10px;
  padding-right: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease, visibility .3s ease;

  @media (max-width: $mobile) {
    padding-top: 15px;
    padding-right: 5px;
  }
}

.overlayActive {
  opacity: 1;
  visibility: visible;
}

.wrap {
  width: 100%;
  max-width: 276px;
  background-color: $darkGray;
  padding: 40px 20px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.nav {
}

.navLink {
  display: block;
  font-size: 18px;
  font-weight: 800;
  line-height: 16.2px;
  letter-spacing: -0.03em;
  color: $white;
  width: max-content;
  margin-bottom: 25px;
}

.connectBtn {
  width: 100%;
}