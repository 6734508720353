@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.wrap {
  width: 100%;
  padding: 10px 10px 0;
  display: flex;
  justify-content: center;

  @media (max-width: $mobile) {
    padding: 15px 5px;
  }
}

.inner {
  @include headerCard;
  max-width: 1290px;
  display: flex;
  align-items: center;

  @media (max-width: $tablet) {
    justify-content: space-between;
  }
}

.logoLink {
  display: flex;
  margin-right: -72px;
}

.logoImage {
  width: 72px;
  height: auto;

  @media (max-width: $tablet) {
    width: 61px;
  }

  @media (max-width: $mobile) {
    width: 53px;
  }
}

.nav {
  display: flex;
  margin: auto;

  @media (max-width: $tablet) {
    display: none;
  }
}

.connectBtn.connectBtn {

  @media (max-width: $tablet) {
    display: none;
  }
}

.burger {
  display: none;

  @media (max-width: $tablet) {
    display: flex;
    position: relative;
    z-index: 10;
  }
}

.burgerIcon {


  @media (max-width: $mobile) {
    width: 30px;
    height: auto;
  }
}

.navLink {
  font-size: 16px;
  font-weight: 800;
  line-height: 14.4px;
  letter-spacing: -0.03em;
  color: $beige2;  

  &:not(:last-child) {
    margin-right: 50px;
  }
}

.navLinkActive {
  color: $green;
}

.burgerBtn {
  display: none;

  @media (max-width: $tablet) {
    display: flex;
    position: relative;
    z-index: 10;
    width: 35px;
    height: 24px;
    padding: 0;
  }

  @media (max-width: $mobile) {
    width: 30px;
    height: 20px;
  }
}

.burgerLine {
  background-color: $white;
  display: block;
  height: 2px;
  opacity: 1;
  position: absolute;
  right: 0;
  left: 0;
  width: 35px;
  border-radius: 10px;
  transition: all 0.3s ease;

  @media (max-width: $mobile) {
    width: 30px;
  }
}

.burgerLine1 {
  top: 0;
}

.burgerLine2 {
  top: 12px;

  @media (max-width: $mobile) {
    top: 10px;
  }
}

.burgerLine3 {
  top: 24px;

  @media (max-width: $mobile) {
    top: 20px;
  }
}

.burgerLine1Active {
  transform: rotate(-45deg);
  top: 14px;

  @media (max-width: $mobile) {
    top: 12px;
  }
}

.burgerLine3Active,
.burgerLine2Active {
  transform: rotate(45deg);
  top: 14px;

  @media (max-width: $mobile) {
    top: 12px;
  }
}

.burgerLine3Active {
  opacity: 0;
  width: 0;
}

.pointWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.point {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: red;
}

.pointActive {
  background-color: green;
}